import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from './en-US';
import cn from './zh-CN';
import store from "@/store"

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale:localStorage.getItem('lang') || 'en_US', // 设置默认语言,
  fallbackLocale: 'en_US',
  // legacy: false,
  // allowComposition: true,
  silentTranslationWarn: true,
  messages: {
    'en_US': en,
    'zh_CN': cn,
  },
});

export default i18n;
