import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locale'
import 'lib-flexible'
import './assets/style/base.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Vue.config.productionTip = false
// const bodyHeight = ref(document.body.clientHeight);
// onElementHeightChange(document.body, (height) => {
//   bodyHeight.value = height;
//   ScrollTrigger.refresh();
// });
// window.addEventListener('resize', () => ScrollTrigger.refresh());

Vue.use(ElementUI);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
