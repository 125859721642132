import localeAbout from './zh-CN/about.json';
import localeNews from './zh-CN/news.json';
import localeContact from './zh-CN/contact.json';
import localeHome from './zh-CN/home.json';
import localeEvents from './zh-CN/events.json';
import localEducation from './zh-CN/education.json';
import localResearch from './zh-CN/research.json';
import localPrivacyPolicy from './zh-CN/PrivacyPolicy.json';

export default {
 
  "screen.tabs.home": "主页",
  "screen.tabs.about": "关于我们",
  "screen.tabs.events": "会议、活动与人际拓展",
  "screen.tabs.research": "研究与咨询",
  "screen.tabs.training": "教育与培训",
  "screen.tabs.news": "新闻动态",
  "screen.tabs.contact": "联系我们",
  "screen.tabs.US": "英文",
  "screen.tabs.EN": "中文",
  "screen.tabs.events.child": "十如对话",
  "screen.footer.email": "联系邮箱：",
  "screen.footer.email.content": "Enquiry@integral.institute",

  "screen.footer.address": "联系地址：",
  "screen.footer.address.content": "香港湾仔港湾道25号海港中心13楼",

  "screen.footer.bottom1": "©2024. Integral Institute. All Rights Reserved",
  "screen.footer.bottom2": "隐私政策",
  "screen.footer.bottom3": "法律声明",
  "member.return": "返回",

  ...localeHome,
  ...localeAbout,
  ...localeNews,
  ...localeContact,
  ...localeEvents,
  ...localPrivacyPolicy,
  ...localEducation,
  ...localResearch
};
