import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    I18nState: localStorage.getItem('lang') || 'en_US',// 语言状态
    headerText: false, 
  },
  getters: {
    getheaderText(state){
      return state.headerText
    },
    getI18nState: state => state.I18nState,
  },
  mutations: {
    setheaderText(state,val){
      state.headerText=val
    },
    setI18nState(state,val){
      state.I18nState=val
    }
  },
  actions: {
   
  },
  modules: {
  }
})
