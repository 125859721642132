import localeAbout from './en-US/about.json';
import localeNews from './en-US/news.json';
import localeContact from './en-US/contact.json';
import localeHome from './en-US/home.json';
import localeEvents from './en-US/events.json';
import localEducation from './en-US/education.json';
import localResearch from './en-US/research.json';
import localPrivacyPolicy from './en-US/PrivacyPolicy.json';

export default {
  "screen.tabs.home": "Home",
  "screen.tabs.about": "About",
  "screen.tabs.events": "Conferences, Events & Networking",
  "screen.tabs.research": "Research & Consultation",
  "screen.tabs.training": "Education & Training",
  "screen.tabs.news": "News",
  "screen.tabs.contact": "Contact",
  "screen.tabs.US": "CN",
  "screen.tabs.EN": "EN",
  "screen.tabs.events.child": "Integral Conversation",
  "screen.footer.email": "Contact email：",
  "screen.footer.email.content": "Enquiry@integral.institute",

  "screen.footer.address": "Contact address：",
  "screen.footer.address.content": "13/F, Harbour Centre, 25 Harbour Road,  Wanchai, Hong Kong",

  "screen.footer.bottom1": "©2024. Integral Institute. All Rights Reserved",
  "screen.footer.bottom2": "Privacy Policy",
  "screen.footer.bottom3": "Terms of Use",
  "member.return": "return",
  ...localeHome,
  ...localeAbout,
  ...localeNews,
  ...localeContact,
  ...localeEvents,
  ...localEducation,
  ...localPrivacyPolicy,
  ...localResearch
};